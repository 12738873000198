<template>
  <!-- ACTION HISTORY MODAL SIDEBAR -->
  <div
    @click="$emit('overlayClicked')"
    class="modal-background-overlay2 h-16"
    :class="{ hidden: !isVisible }"
  ></div>
  <div
    class="modal-background-overlay"
    @click="$emit('overlayClicked')"
    :class="{ hidden: !isVisible }"
  ></div>
  <div
    class="modal-background-overlay3"
    @click="$emit('overlayClicked')"
    :class="{
      'modal-background-overlay--hidden': !isVisible,
      'modal-background-overlay--black': isVisible,
    }"
  ></div>
  <div
    style="z-index: 998"
    :class="{ 'modal-visible': isVisible }"
    class="
      modal
      bottom-0
      pb-12
      pt-28
      px-8
      overflow-y-auto
      bg-white
      w-1/3
      text-black
      flex flex-col
      z-50
      h-full
      fixed
    "
  >
    <div class="text-left z-50">
      <h1 class="text-xl font-bold">Add Brand</h1>

      <div class="leading-loose">
        <form @submit.prevent class="mt-4 bg-white">
          <div class="inline-block mt-2 w-full pr-1">
            <input
              class="w-full px-2 py-2 text-sm text-gray-700 bg-gray-200 rounded"
              name="actiontitle"
              type="text"
              placeholder="Name"
              v-model="newBrand.name"
            />
          </div>

          <div class="font-bold mt-5">Brand Logo</div>
          <img
            v-if="logo"
            :src="logo"
            class="block mx-auto my-2"
            style="max-height: 200px; width: auto"
          />
          <label
            v-if="!files.logo"
            for="logoInput"
            class="text-center cursor-pointer block bg-gray-100 p-1 w-full"
            >+
            <input
              @change="onFileSelect($event, 'logo')"
              type="file"
              class="hidden"
              name="logoInput"
              id="logoInput"
              ref="logoInput"
            />
          </label>
          <button
            v-if="files.logo"
            @click="files.logo = null"
            for="logoInput"
            class="
              text-center text-red-500
              font-bold
              cursor-pointer
              block
              bg-yellow-100
              p-1
              w-full
            "
          >
            x
          </button>

          <div class="mt-6">
            <button
              class="
                px-4
                mr-2
                py-2
                text-black
                font-light
                text-sm
                tracking-wider
                bg-gray-100
                hover:bg-gray-200
                rounded
              "
              @click.prevent="$emit('overlayClicked')"
            >
              Cancel
            </button>
            <button
              class="
                px-4
                py-2
                text-sm text-white
                font-light
                tracking-wider
                bg-gray-900
                hover:bg-gray-700
                rounded
              "
              :disabled="!canSave"
              :class="{
                'cursor-not-allowed': !canSave,
              }"
              type="submit"
              @click.prevent="saveBrand"
            >
              Add
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- INQUIRY MODAL SIDEBAR -->
</template>

<script>
import alerts from "@/utils/alert-types";
import { mapActions } from "vuex";

export default {
  name: "NewBrandModal",
  props: ["isVisible"],
  emits: ["overlayClicked"],
  data() {
    return {
      files: {
        brandLogo: null,
      },
      newBrand: {
        name: "",
      },
    };
  },
  methods: {
    ...mapActions(["addDocumentBrand"]),
    async saveBrand() {
      try {
        await this.addDocumentBrand({
          brand: this.newBrand,
          logo: this.files.logo,
        });
        this.$emit("overlayClicked");
        this.files = {
          logo: null,
        };
        this.newBrand = {
          name: "",
        };
      } catch (e) {
        this.$errorHandler(e);
      }
    },
    onFileSelect(e, type) {
      let files = [];

      if (e.target.files.length) {
        const fileList = e.target.files;

        for (const key in fileList) {
          if (typeof fileList[key] == "object") {
            if (fileList[key].type) {
              let allowed =
                fileList[key].type === "image/jpeg" ||
                fileList[key].type === "image/jpg" ||
                fileList[key].type === "image/png" ||
                fileList[key].type === "image/gif";

              if (type === "file") {
                allowed =
                  fileList[key].type ===
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                  fileList[key].type === "application/pdf" ||
                  fileList[key].type ===
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                  fileList[key].type === "application/vnd.ms-excel";
              }

              if (allowed) {
                files.push(fileList[key]);
              } else {
                this.$store.dispatch("addAlert", {
                  ...alerts.danger,
                  alert: "one of the files you selected is not allowed!",
                });
              }
            }
          }
        }
        this.files[type] = files[0];
      }

      this.$refs[`${type}Input`].value = null;
    },
  },
  computed: {
    canSave() {
      return this.newBrand.name && this.files && this.files.logo;
    },
    logo() {
      this.files.logo;

      if (!this.files || !this.files.logo) return null;

      const url = URL.createObjectURL(this.files.logo);

      return url;
    },
  },
};
</script>

<style scoped>
.modal-background-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 997;
}

.modal-background-overlay2 {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1001;
}

.modal-background-overlay3 {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 49;
  transition: background 0.2s 0.3s, visibility 1s;
}

.modal-background-overlay--hidden {
  visibility: hidden;
}

.modal-background-overlay--black {
  background: rgba(0, 0, 0, 0.8);
  visibility: visible;
}

.modal {
  transition: transform 0.5s;
  right: 0;
  transform: translatex(100%);
}

.modal-visible {
  transform: translatex(0%);
}
</style>